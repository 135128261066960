import { useEffect, useState } from "react";
import { FaceLivenessDetector } from "@aws-amplify/ui-react-liveness";
import { solicitud } from "../models/solicitud";
import { FadeLoader } from "react-spinners";
import { Alert, ThemeProvider, useTheme } from "@aws-amplify/ui-react";
import { esLang, enLang } from "../services/lang";
import "../components/custom.css";
import "@aws-amplify/ui-react/styles.css";

export function LivenessQuickStart(props) {
  const { primaryColor, instructions, variant, url, userId } = props;
  const [loading, setLoading] = useState(true);
  const [createLivenessApiData, setCreateLivenessApiData] = useState(null);
  const [retryKey, setRetryKey] = useState(0);
  const [lang, setLang] = useState({});
  const language = sessionStorage.getItem("language");
  const [themeProvider, setThemeProvider] = useState(null);
  const { tokens } = useTheme();
  const [finalResult, setFinalResult] = useState(null);
  const [awaitValidation, setAwaitValidation] = useState(false);

  useEffect(() => {
    if (primaryColor !== null) {
      const theme = {
        tokens: {
          components: {
            button: {
              backgroundColor: { value: tokens.colors[primaryColor][variant] },
              borderColor: { value: tokens.colors[primaryColor][variant] },

              primary: {
                backgroundColor: {
                  value:
                    tokens.colors[primaryColor][
                      variant === "10"
                        ? "20"
                        : variant === "20"
                        ? "40"
                        : variant === "40"
                        ? "60"
                        : variant === "60"
                        ? "80"
                        : variant === "80"
                        ? "90"
                        : variant === "90"
                        ? "100"
                        : "90"
                    ],
                },
                color: { value: "white" },
                _hover: {
                  backgroundColor: {
                    value:
                      tokens.colors[primaryColor][
                        variant === "10"
                          ? "20"
                          : variant === "20"
                          ? "40"
                          : variant === "40"
                          ? "60"
                          : variant === "60"
                          ? "80"
                          : variant === "80"
                          ? "90"
                          : variant === "90"
                          ? "100"
                          : "90"
                      ],
                  },
                },
                _focus: {
                  backgroundColor: {
                    value: tokens.colors[primaryColor][variant],
                  },
                },
                _active: {
                  backgroundColor: {
                    value: tokens.colors[primaryColor][variant],
                  },
                },
              },
            },
          },
          colors: {
            background: {
              primary: {
                value:
                  /* primaryColor === "white"
                    ? tokens.colors.white
                    : primaryColor === "black"
                    ? tokens.colors.black
                    : tokens.colors[primaryColor][variant] */ "#FFF;",
              },
            },
            font: {
              primary: {
                value:
                  primaryColor === "white"
                    ? tokens.colors.black
                    : primaryColor === "black"
                    ? tokens.colors.white
                    : primaryColor === "neutral"
                    ? tokens.colors.black
                    : /* tokens.colors.black */ "#A61932",
              },
            },
            brand: {
              primary: {
                10:
                  primaryColor === "white"
                    ? tokens.colors.white
                    : primaryColor === "black"
                    ? tokens.colors.black
                    : tokens.colors[primaryColor][variant],
                80:
                  primaryColor === "white"
                    ? tokens.colors.white
                    : primaryColor === "black"
                    ? tokens.colors.black
                    : tokens.colors[primaryColor][variant],
                90:
                  primaryColor === "white"
                    ? tokens.colors.white
                    : primaryColor === "black"
                    ? tokens.colors.black
                    : tokens.colors[primaryColor][variant],
                100:
                  primaryColor === "white"
                    ? tokens.colors.white
                    : primaryColor === "black"
                    ? tokens.colors.black
                    : tokens.colors[primaryColor][variant],
              },
            },
          },
        },
      };

      setThemeProvider(theme);
    }
  }, [primaryColor]);

  useEffect(() => {
    setLang(language === "ES" ? esLang["es"] : enLang["en"]);
  }, [language]);

  useEffect(() => {
    const fetchCreateLiveness = async () => {
      const response = await solicitud.getSessionId(userId);
      if (response && response.data) {
        console.log(response.data);
        setCreateLivenessApiData(response.data);
        setLoading(false);
      }
    };

    fetchCreateLiveness();
  }, [retryKey]);

  const handleAnalysisComplete = async (id) => {
    setAwaitValidation(true);
    const response = await solicitud.getResult(id);
    if (response) {
      window.parent.postMessage(response.data, "*");
      setFinalResult(response.data.status);
    }
  };

  const handleUserCancel = () => {
    setRetryKey((prevKey) => prevKey + 1);
  };

  if (finalResult !== null) {
    window.location.replace(url);
  }

  return (
    <div
      style={{
        backgroundColor: "white",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column",
        gap: "1rem",
        height: "100%",
        width: "100%",
      }}
    >
      {loading && createLivenessApiData === null && primaryColor ? (
        <FadeLoader
          style={{ scale: "0.7" }}
          /* color={primaryColor} */ color="#A61932"
        />
      ) : (
        <ThemeProvider theme={themeProvider}>
          {!awaitValidation && (
            <FaceLivenessDetector
              key={retryKey}
              onUserCancel={handleUserCancel}
              disableStartScreen={instructions === "false" ? true : false}
              displayText={lang}
              sessionId={createLivenessApiData}
              region="us-east-1"
              onError={(e) => console.log(e)}
              onAnalysisComplete={() =>
                handleAnalysisComplete(createLivenessApiData)
              }
              components={{
                PhotosensitiveWarning: () => {
                  return (
                    <Alert
                      style={{
                        boxShadow: "0px 0px 6px black",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        gap: " 0.5rem",
                      }}
                      variation="warning"
                      isDismissible={false}
                      hasIcon={true}
                      heading={language === "ES" ? "Precaución" : "Warning"}
                    >
                      {language === "ES" ? (
                        <>
                          Esta verificación muestra luces de colores. Tenga
                          cuidado si es fotosensible
                        </>
                      ) : (
                        <>
                          This check flashes different colors. Use caution if
                          you are photosensitive.
                        </>
                      )}
                    </Alert>
                  );
                },
              }}
            />
          )}
          {awaitValidation && (
            <div
              style={{
                position: "absolute",
                left: "50%",
                top: "50%",
                transform: "translate(-50%, -50%)",
                scale: "0.7",
              }}
            >
              <FadeLoader color="#A61932" />
            </div>
          )}
        </ThemeProvider>
      )}
    </div>
  );
}
