import http from "../services/http";

const getSessionId = async (userId) => {
  if (userId) {
    const url = `${process.env.REACT_APP_API_URL}/create-session/${userId}`;
    const response = await http.get(url);
    const data = response;
    return data;
  }
};

const getResult = async (id) => {
  const url = `${process.env.REACT_APP_API_URL}/face-liveness/${id}`;
  const response = await http.get(url);

  return response;
};

export const solicitud = {
  getResult: getResult,
  getSessionId: getSessionId,
};
