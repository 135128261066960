import { View } from "@aws-amplify/ui-react";
import "@aws-amplify/ui-react/styles.css";
import { LivenessQuickStart } from "./components/Liveness";
import { useEffect } from "react";

function App({ Component, pageProps }) {
  const urlParams = new URLSearchParams(window.location.search);
  const primaryColor = urlParams.get("primary");
  const userId = urlParams.get("userId");
  const variant = urlParams.get("variant");
  const instructions = urlParams.get("instructions");
  const language = urlParams.get("language");
  const url = urlParams.get("url");

  useEffect(() => {
    if (language) sessionStorage.setItem("language", language);
  }, [language]);

  return (
    <View width="100vw" height="100vh" margin="0 auto">
      {primaryColor && instructions && userId && url  && variant ? (
        <LivenessQuickStart
          userId={userId}
          url={url}
          variant={variant}
          primaryColor={primaryColor}
          instructions={instructions}
        />
      ) : (
        <></>
      )}
    </View>
  );
}

export default App;
